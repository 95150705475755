import React from 'react';

function Home() {
  return (
    <div>
      <h2>Welcome to Yarn Management</h2>
      <p>Where you can manage your yarn projects and inventory easily.</p>
    </div>
  );
}

export default Home;
